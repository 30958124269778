<template>
  <div>
    <v-card flat>
      <v-card-title>
        <label class="primary--text px-5 font-weight-bold info-page-title ma-auto text-capitalize">{{ $t(`label.otherTopics`) }}</label>
      </v-card-title>
    </v-card>
    <v-card class="text-left">
      <div v-for="link in infoLink" :key="link.text_key">
        <v-btn link text :to="{ name: link.routeName, params: { lang: $route.params.lang } }" class="text-left full-width info-navigation-button body-2" :ripple="false">{{ $t(`label.${link.text_key}`) }}</v-btn>
        <v-divider></v-divider>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'

export default {
  name: 'appInfoSideMenu',
  components: {},
  data: () => ({
    infoLink: [
      {
        text_key: 'faq',
        routeName: ROUTE_NAME.FAQ
      },
      {
        text_key: 'aboutUs',
        routeName: ROUTE_NAME.ABOUT_US
      },
      {
        text_key: 'contactUs',
        routeName: ROUTE_NAME.CONTACT_US
      },
      {
        text_key: 'responsibleGaming',
        routeName: ROUTE_NAME.RESPONSIBLE_GAMING
      },
      {
        text_key: 'tnc',
        routeName: ROUTE_NAME.TNC
      }
    ]
  })
}
</script>

<style lang="scss">
.info-navigation-button {
  letter-spacing: 0;
  border-radius: 0px;
  padding: 24px !important;
  &.v-btn--active {
    span {
      font-weight: bold;
      color: var(--v-navigator_active-base);
    }
  }
  span {
    display: inline-block;
  }
}

@media (max-width: 1263px) {
  .info-navigation-button {
    padding: 10px 20px !important;
    min-height: auto;
    height: auto;
  }
}
</style>
